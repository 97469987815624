<template>
  <div class="form jn-form-style">
    <div class="form-item btn-wrap">
      <el-button
        type="primary"
        style="margin-left: 5px"
        icon="el-icon-refresh"
        class="refresh"
        @click="onHandleRefresh"
      ></el-button>
    </div>

    <div class="form-item">
      <el-input
        placeholder="供货单号"
        v-model="formData.supplier_order_no"
        clearable
      ></el-input>
    </div>

    <div class="form-item">
      <el-date-picker
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="供货日期"
        v-model="formData.supplier_order_time"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </div>

    <div class="form-item">
      <el-input
        placeholder="供应商名称"
        v-model="formData.supplier_title"
        clearable
      ></el-input>
    </div>

    <div class="form-item">
      <el-date-picker
        type="daterange"
        range-separator="至"
        value-format="yyyy-MM-dd"
        start-placeholder="打印时间起"
        end-placeholder="打印时间止"
        v-model="dateTime"
        :picker-options="pickerOptions"
        @change="onChangeDate"
      >
      </el-date-picker>
    </div>

    <div class="form-item">
      <el-input
        placeholder="操作人"
        v-model="formData.business_user_name"
        clearable
      ></el-input>
    </div>

    <div class="form-item">
      <el-select v-model="formData.type" placeholder="打印类型" clearable>
        <el-option
          v-for="item in printStatusList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <div class="form-item btn-wrap">
      <el-button @click="onHandleSearch" type="primary" icon="el-icon-search"
        >查询</el-button
      >
      <el-button
        @click="onHandleExport"
        :disabled="!tableData.length || disabledExport"
        type="warning"
        icon="el-icon-download"
        >导 出</el-button
      >
    </div>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import {
  getPrintLabelLogOfOrder,
  getPrintLabelLogOfOrderExport,
} from "@/api/sheet-to-review/index";
import { PRINT_STATUS_MAP } from "../../utils/enum/index";

export default {
  name: "FormFilter",
  props: {
    /**
     * 携带参数
     */
    pageParams: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      disabledExport: false,
      printStatusList: Object.values(PRINT_STATUS_MAP), // 打印类型
      dateTime: [], // 日期对象
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      }, // 时间范围
      formData: {
        supplier_order_no: "", // 供货单号
        supplier_order_time: "", // 供货日期
        supplier_title: "", // 供应商名称
        start_time: "", // 打印时间起
        end_time: "", // 打印时间止
        business_user_name: "", // 操作人
        type: "", // 打印类型
      },
      formDataClone: {}, // 复制一份，用于初始化
      tableData: [],
      total: 0,
    };
  },
  methods: {
    /**
     * 初始化数据
     */
    onInitData() {
      this.formDataClone = cloneDeep(this.formData);
      this.ajaxGetTableData();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.$parent.pageParams.page = 1;
      this.$parent.pageParams.pageSize = 10;
      this.dateTime = [];
      this.ajaxGetTableData();
    },
    /**
     * 查询表单
     */
    onHandleSearch() {
      this.$parent.pageParams.page = 1;
      this.ajaxGetTableData();
    },
    /**
     * 查询列表数据
     */
    async ajaxGetTableData() {
      this.$parent.loadingData = true;
      const params = { ...this.formData, ...this.pageParams };
      try {
        const { data } = await getPrintLabelLogOfOrder(params);
        const { total, data: list } = data;
        this.tableData = list;
        this.total = total;
        const resData = {
          total,
          tableData: list,
        };
        // 更新父级列表数据
        Object.keys(resData).forEach((_key) => {
          this.$parent[_key] = resData[_key];
        });
        console.log("🆒 getPrintLabelLogOfOrder", resData);
      } catch (err) {
        console.log("getPrintLabelLogOfOrder err", err);
      } finally {
        this.$parent.loadingData = false;
      }
    },
    /**
     * 修改了每页数据条数
     */
    onHandleSizeChange(val) {
      this.$parent.pageParams.page = 1;
      this.$parent.pageParams.pageSize = val;
      this.ajaxGetTableData();
    },
    /**
     * 修改了页码
     */
    onHandleCurrentChange(val) {
      this.$parent.pageParams.page = val;
      this.ajaxGetTableData();
    },
    /**
     * 日期发生改变
     */
    onChangeDate(data) {
      this.formData.start_time = data?.[0] || "";
      this.formData.end_time = data?.[1] || "";
    },
    /**
     * 导出数据
     */
    async onHandleExport() {
      this.disabledExport = true;
      try {
        // 组合需要的参数
        const query = {
          ...this.formData,
        };
        await getPrintLabelLogOfOrderExport(query);
      } catch (err) {
        console.log("ajax postMemberListExport err", err);
      } finally {
        this.disabledExport = false;
      }
    },
  },
};
</script>
