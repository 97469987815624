/**
 * 打印类型
 */
export const PRINT_STATUS_MAP = {
  PORTION: {
    value: 1,
    label: '部分打印'
  },
  ALL: {
    value: 2,
    label: '全部打印'
  },
}
