var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      staticStyle: { width: "100%" },
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
        "tooltip-effect": "dark",
      },
    },
    [
      _c("el-table-column", {
        attrs: {
          type: "index",
          width: "100px",
          align: "center",
          label: "序号",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "supplier_order",
          align: "center",
          label: "供货单号",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      scope.row.supplier_order &&
                        scope.row.supplier_order.supplier_order_no
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "supplier_order", align: "center", label: "供货日期" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      scope.row.supplier_order &&
                        scope.row.supplier_order.create_time
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "supplier",
          align: "center",
          label: "供货商名称",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      (scope.row.supplier && scope.row.supplier.title) || "-"
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "name", align: "center", label: "打印类型" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.printStatusList.find(
                        (item) => item.value === scope.row.type
                      ).label
                    )
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "sku_code",
          align: "center",
          label: "商品编号",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.sku_code || "-") + " ")]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "product_title",
          align: "center",
          label: "品名",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(" " + _vm._s(scope.row.product_title || "-") + " "),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "print_num", align: "center", label: "数量" },
      }),
      _c("el-table-column", {
        attrs: { prop: "create_time", align: "center", label: "打印时间" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "business_user",
          align: "center",
          label: "操作人",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      (scope.row.business_user &&
                        scope.row.business_user.nickname) ||
                        "-"
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "remark",
          align: "center",
          label: "打印原因",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.remark || "-") + " ")]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }