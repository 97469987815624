<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
    tooltip-effect="dark"
    style="width: 100%"
  >
    <el-table-column type="index" width="100px" align="center" label="序号">
    </el-table-column>

    <el-table-column
      prop="supplier_order"
      align="center"
      label="供货单号"
      min-width="100"
    >
      <template slot-scope="scope">
        {{
          scope.row.supplier_order && scope.row.supplier_order.supplier_order_no
        }}
      </template>
    </el-table-column>

    <el-table-column prop="supplier_order" align="center" label="供货日期">
      <template slot-scope="scope">
        {{ scope.row.supplier_order && scope.row.supplier_order.create_time }}
      </template>
    </el-table-column>

    <el-table-column
      prop="supplier"
      align="center"
      label="供货商名称"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        {{ (scope.row.supplier && scope.row.supplier.title) || "-" }}
      </template>
    </el-table-column>

    <el-table-column prop="name" align="center" label="打印类型">
      <template slot-scope="scope">
        <span>{{
          printStatusList.find((item) => item.value === scope.row.type).label
        }}</span>
      </template>
    </el-table-column>

    <el-table-column
      prop="sku_code"
      align="center"
      label="商品编号"
      min-width="100"
    >
      <template slot-scope="scope">
        {{ scope.row.sku_code || "-" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="product_title"
      align="center"
      label="品名"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        {{ scope.row.product_title || "-" }}
      </template>
    </el-table-column>

    <el-table-column prop="print_num" align="center" label="数量">
    </el-table-column>

    <el-table-column prop="create_time" align="center" label="打印时间">
    </el-table-column>

    <el-table-column
      prop="business_user"
      align="center"
      label="操作人"
      min-width="100"
    >
      <template slot-scope="scope">
        {{
          (scope.row.business_user && scope.row.business_user.nickname) || "-"
        }}
      </template>
    </el-table-column>

    <el-table-column
      prop="remark"
      align="center"
      label="打印原因"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        {{ scope.row.remark || "-" }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { PRINT_STATUS_MAP } from "../../utils/enum/index";

export default {
  name: "TableList",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      PRINT_STATUS_MAP,
      printStatusList: Object.values(PRINT_STATUS_MAP), // 打印类型
    };
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
